/*
* Main
*/
.body-top-right {
  position: absolute;
  top: 10%;
  right: 16px;
}
.contentbox-left {
  position: absolute;
  border-radius: 3%;
  top: 20%;
  left: 5%;
  width: 40%;
  height: 70%;
  background-color: rgb(0, 0, 0);
  opacity: 0.5;
  box-shadow: 0 0 8px 8px rgb(20, 33, 73) inset;
}
.contentbox-right {
  position: absolute;
  border-radius: 3%;
  top: 20%;
  right: 5%;
  width: 40%;
  height: 70%;
  background-color: black;
  opacity: 0.5;
  box-shadow: 0 0 8px 8px rgb(20, 33, 73) inset;
}

/*
* Bootstrap override
*/
.navbar {
  background: none;
  font-family: Verdana !important;
  font-size: 20px !important;
}
.bg-image {
  opacity: 0.9;
  filter: blur(1px);
  -webkit-filter: blur(1px);
}

/*
* Dark Mode CSS
*/
.App-header {
  text-align: left;
  color: whitesmoke;
  background-color: #282c34;
  display: flex;
}
.App-background {
  background-color: #282c34;
  color: whitesmoke;
}
.body-center-text-dark {
  position: absolute;
  top: 30%;
  left: 10%;
  color: white;
  font-family:'digital-clock-font';
  font-size: 200%;
}

/*
* Light Mode CSS
*/
.App-background-light {
  background-color: whitesmoke;
  color: rgb(0, 0, 0);
}
.App-header-light {
  text-align: left;
  color: black;
}
.body-center-text-light {
  position: absolute;
  top: 30%;
  left: 10%;
  color: black;
  font-family:'digital-clock-font';
  font-size: 200%;
}
